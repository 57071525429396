import React from "react"
import { Link, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import Layout from '../components/Layout/Layout'

export default function NotFound({ data }) {
  return (
    <Layout title="404">
      <div className="center-text">
        <h1>{data.markdownRemark.frontmatter.header}</h1>
        <ReactMarkdown source={data.markdownRemark.frontmatter.message} />
        <Link to="/">Go to Home Page</Link>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query NotFoundQuery {
  markdownRemark(frontmatter: {title: {eq: "404"}}) {
    frontmatter {
      header
      message
    }
  }
}
`